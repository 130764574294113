<template>
  <b-card no-body class="mb-5">
    <b-card-header>
        <b-col :md="onlyTitle ? 12 : 3" class="d-flex px-0">
          <b-card-title title-tag="h5">{{ title }}</b-card-title>
        </b-col>
        <b-col md="9">
          <b-row v-if="this.$slots.header" class="justify-content-end">
            <slot name="header" />
          </b-row>
        </b-col>
    </b-card-header>
    <template v-if="useTable">
      <slot />
    </template>
    <b-card-body v-else>
      <slot />
    </b-card-body>
    <b-card-footer v-if="this.$slots.footer">
      <slot name="footer" />
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  props: {
    onlyTitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    useTable: {
      type: Boolean,
    }
  }
}
</script>
<style lang="scss" scoped>
  .card-header{
    display: flex;
    .card-title{flex: 1;}
    .filters{flex: 4;padding-left: 16px;}
  }
</style>