<template>
  <div class="card-img">
    <b-card-img
      v-if="!isVideo"
      :alt="title"
      :src="src"
      :blank="!src"
      blank-color="#ccc"
      style="min-height: 128px; max-height: 128px; object-fit: cover;"
    />
    <b-embed
      v-else
      type="iframe"
      aspect="16by9"
      :src="src"
      allowfullscreen
    ></b-embed>
    <template v-if="!isTitleHtml">
      <span v-if="title !== undefined" class="card-img__title">{{ title || '-' }}</span>
    </template>
    <template v-else>
      <span v-html="title"></span>
    </template>
    <div class="floating-status">
      <slot name="status" />
    </div>
    <div class="bullet-cta">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    src: {
      type: String,
    },
    isTitleHtml: {
      type: Boolean,
      default: false
    },
    isVideo: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
